<template>
  <el-dialog title="学院信息编辑" class="info-div600" style="padding: 24px;text-align: left"
             :close-on-press-escape="false" :close-on-click-modal="false">
    <el-form-item label="名称" label-width="80px">
      <el-input v-model="Info.name" autocomplete="off" placeholder="请输入"/>
    </el-form-item>
    <div class="dialog-footer marginTop30px text-right">
      <button class="subBtn" @click="subData">提交</button>
      <button class="qxBtn" @click="closeDiv">取消</button>
    </div>
  </el-dialog>
</template>
<script>
import {addCollege} from "@/api/api";
export default {
  name: "index",
  props: ['Row'],
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {
        id: '',
        name: '',
      },
      content: [''],
      adminOptions: [], //管理员
    }
  },
  mounted() {
  },
  methods: {
    closeDiv() {
      this.$emit('closeDiv');
    },
    getInfo(row) {//清空表单
      if (row) {
        this.Info = JSON.parse(row);
      } else {
        this.Info = {};
      }
    },
    changeDic(index, type) {
      if (type == 'add') {
        this.content.push('');
      } else if (type == 'reced') {
        this.content.splice(index, 1);
      }
    },
    subData() {//提交
      if ((!this.Info.name)) {
        this.$root.ElMessage.error('名称不能为空！');
        return false;
      }
      let method = 'post';
      if (this.Info.id) {
        method = 'put';
      }
      addCollege(this.Info, method).then((res) => {
        if (res.success) {
          this.$root.ElMessage({type: 'success', message: res.message});
          this.closeDiv();
          this.$emit('getList');
        } else {
          this.$root.ElMessage.error(res.message);
        }
      })
    },
    handleAvatarSuccess(e) {//图片上传成功后
      this.Info.businessLicenseImg = e.data;
    }
  }
}
</script>

<style lang="scss" scoped>
  .child_Dic{
    .icon_dic_css{font-size: 20px;margin-left: 10px;color: #cccccc}
  }
</style>